import { Grid } from "@mui/material";
import Bottom from "../../components/Bottom";
import Solutions from "../../components/Solutions";
import WhyBoxC from "./WhyBoxC";
import { HomeHero } from "./Hero";
import Navigation from "../../components/Navigation";
import { HomeHelmet } from "../../components/MetaHelmet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Error from "../error/index";
import InfoCard from "../../components/InfoCard";

/**
 * @component
 * @module Home
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route index element={<Home/>} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * Home Page
 */
const Home = () => {
  const location = useLocation();
  const { pathname } = location;
  const [error, setError] = useState(false);
  const handleReferral = (props) => {
    const { pathname, handleError } = props;

    try {
      const userId = pathname.split("/r/")[1];
      const cookieName = "refid";
      const cookieValue = userId;
      const now = new Date();
      const thirtyDate = now;
      thirtyDate.setDate(thirtyDate.getDate() + 30);
      const thirtyTime = thirtyDate.getTime();
      now.setTime(thirtyTime);

      document.cookie =
        cookieName +
        "=" +
        cookieValue +
        ";expires=" +
        now.toUTCString() +
        ";domain=.boxc.com;path=/";
    } catch (error) {
      console.error("REFERRAL LINK ERROR: ", error);
      handleError(
        "There was an issue setting your referral link. Please try again later."
      );
    }
  };

  useEffect(() => {
    if (pathname.includes("/r/")) {
      handleReferral({ pathname: pathname, handleError: setError });
    }
  }, [pathname]);

  return (
    <Grid container item xs={12}>
      {!!error ? (
        <Error message={error} />
      ) : (
        <>
          <HomeHelmet />
          <Navigation />
          <HomeHero />
          {/*<WhatIsBoxC />*/}
          <InfoCard page="home" index={0} />
          <Solutions />
          <WhyBoxC />
          <Bottom showPreFooter showNav />
        </>
      )}
    </Grid>
  );
};

export default Home;
