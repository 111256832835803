import { Grid } from "@mui/material";
import { AboutHero } from "./Hero";
import Bottom from "../../components/Bottom";
import Solutions from "../../components/Solutions";
import Navigation from "../../components/Navigation";
import { AboutHelmet } from "../../components/MetaHelmet";
import GridCard from "../../components/GridCard";
import MapCard from "../../components/MapCard";
import Billboard from "../../components/Billboard";
import StatementCard from "../../components/StatementCard";
import ListCard from "../../components/ListCard";

/**
 * @component
 * @module About
 * @category Client
 * @subcategory Pages
 * @type {React | JSX}
 * @example <caption>Usage:</caption>
 * return (
 *   <BrowserRouter>
 *     <Routes>
 *       <Route exact path="/about" element={<About />} />
 *     </Routes>
 *   </BrowserRouter>
 * )
 * @description
 * About Page
 */
const About = () => {
  return (
    <Grid container item alignItems="center">
      <AboutHelmet />
      <Navigation />
      <AboutHero />
      {/* OUR VALUES */}
      <GridCard page="about" index={0} />
      {/* WHY CHOOSE BOXC */}
      <ListCard page="about" index={0} />
      <Billboard page="about" index={0} />
      <MapCard />
      <StatementCard page="about" index={0} />
      <Solutions />
      <Bottom showPreFooter showNav />
    </Grid>
  );
};

export default About;
