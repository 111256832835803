import { useEffect } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { SolutionsButton } from "./Button";
import { Desktop } from "../layout";
import { Img } from "./Image";
import useStrapi from "../hooks/useStrapi";

/**
 * @component
 * @module Solutions
 * @category Client
 * @subcategory Components
 * @type {React | JSX}
 * @description
 * Solutions Component
 * @example <caption>Usage:</caption>
 * return(
 *   <Solutions />
 * )
 * @return {JSX.Element}
 */
const Solutions = () => {
  const { solutionsModule, fetchSolutionsModule, fetchingSolutionsModule } =
    useStrapi();

  useEffect(() => {
    if (solutionsModule === null && !fetchingSolutionsModule.current) {
      fetchSolutionsModule();
    }
  }, [solutionsModule, fetchSolutionsModule, fetchingSolutionsModule]);

  return (
    <>
      {!!solutionsModule && (
        <Grid container item>
          <Grid container item px={{ xs: 0, lg: 10 }} maxWidth={1440} mx="auto">
            {!!solutionsModule && (
              <>
                {/* DIAGONAL TITLE BAR */}
                <Grid item xs={12}>
                  <Desktop>
                    <Box
                      sx={{
                        position: "relative",
                        left: 550,
                        top: 30,
                        height: 57,
                        width: 180,
                        transform: "matrix(0.96, 0.25, -0.27, 0.97, 0, 0)",
                        backgroundColor: "lavender.main",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "16px, 41px, 16px, 41px",
                      }}
                    >
                      <Typography
                        variant={solutionsModule["title"]["font"]}
                        color={
                          solutionsModule["title"]["color"] === "dark"
                            ? "black.main"
                            : "gray.main"
                        }
                        textAlign="center"
                      >
                        {solutionsModule["title"]["value"]}
                      </Typography>
                    </Box>
                  </Desktop>
                </Grid>
                {/* LEFT SIDE */}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  zeroMinWidth
                  sx={{ backgroundColor: "#21232A" }}
                >
                  <Box>
                    <Stack alignItems="center" rowGap={2}>
                      {/* TITLE */}
                      <Box mt="63px" mx={{ lg: "48px" }}>
                        <Typography
                          variant={solutionsModule["leftSide"]["title"]["font"]}
                          color={
                            solutionsModule["leftSide"]["title"]["color"] ===
                            "light"
                              ? "neutral.white"
                              : "black.main"
                          }
                        >
                          {solutionsModule["leftSide"]["title"]["value"]}
                        </Typography>
                      </Box>
                      {/* SUBTITLE */}
                      <Typography
                        variant={
                          solutionsModule["leftSide"]["subTitle"]["font"]
                        }
                        color={
                          solutionsModule["leftSide"]["subTitle"]["color"] ===
                          "light"
                            ? "black.light"
                            : "black.main"
                        }
                        pb={4}
                        px={{ xs: 5, lg: 20 }}
                        textAlign="center"
                      >
                        {solutionsModule["leftSide"]["subTitle"]["value"]}
                      </Typography>
                      {/* BUTTON */}
                      <SolutionsButton
                        text={`Solutions for ${solutionsModule["leftSide"]["title"]["value"]}`}
                        width="auto"
                        link={solutionsModule["leftSide"]["href"]}
                      />
                      {/* IMAGE */}
                      <Img
                        src={solutionsModule["leftSide"]["image"]}
                        alt="first-solution-img"
                        maxHeight="406px"
                        maxWidth="414px"
                        mx={{ xs: 2, lg: "auto" }}
                        pt={{ xs: 6, lg: 12 }}
                        pb={{ xs: 2, lg: 10 }}
                      />
                    </Stack>
                  </Box>
                </Grid>
                {/* RIGHT SIDE */}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  zeroMinWidth
                  sx={{ backgroundColor: "black.dark" }}
                >
                  <Box>
                    <Stack alignItems="center" rowGap={2}>
                      {/* TITLE */}
                      <Box mt="63px" mx={{ lg: "48px" }}>
                        <Typography
                          variant={
                            solutionsModule["rightSide"]["title"]["font"]
                          }
                          color={
                            solutionsModule["rightSide"]["title"]["color"] ===
                            "light"
                              ? "neutral.white"
                              : "black.main"
                          }
                        >
                          {solutionsModule["rightSide"]["title"]["value"]}
                        </Typography>
                      </Box>
                      {/* SUBTITLE */}
                      <Typography
                        variant={
                          solutionsModule["rightSide"]["subTitle"]["font"]
                        }
                        color={
                          solutionsModule["rightSide"]["subTitle"]["color"] ===
                          "light"
                            ? "black.light"
                            : "black.main"
                        }
                        pb={4}
                        px={{ xs: 8, lg: 25 }}
                        textAlign="center"
                      >
                        {solutionsModule["rightSide"]["subTitle"]["value"]}
                      </Typography>
                      {/* BUTTON */}
                      <SolutionsButton
                        text={`Solutions for ${solutionsModule["rightSide"]["title"]["value"]}`}
                        width="auto"
                        link={solutionsModule["rightSide"]["href"]}
                      />
                      {/* IMAGE */}
                      <Img
                        src={solutionsModule["rightSide"]["image"]}
                        alt="second-solution-img"
                        maxHeight="406px"
                        maxWidth="414px"
                        mx={{ xs: 2, lg: "auto" }}
                        pt={12}
                        pb={2}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Solutions;
