import { createContext, useCallback, useReducer, useRef } from "react";
import axios from "axios";
import { getDateMonthString } from "../utils/dates";
import _ from "underscore";

const page_headers = {
  about: null,
  "e-commerce": null,
  home: null,
  howItWorks: null,
  logistics: null,
  response: null,
};

const BILLBOARDS = "BILLBOARDS";
const CALL_TO_ACTIONS = "CALL_TO_ACTIONS";
const COLUMN_CARDS = "COLUMN_CARDS";
const FOOTER = "FOOTER";
const GRID_CARDS = "GRID_CARDS";
const HEADERS = "HEADERS";
const INFO_CARDS = "INFO_CARDS";
const LIST_CARDS = "LIST_CARDS";
const LIST_MEDIA_CARDS = "LIST_MEDIA_CARDS";
const MAP_CARD = "MAP_CARD";
const MEDIA_CARDS = "MEDIA_CARDS";
const PREFOOTER = "PREFOOTER";
const PRIVACY = "PRIVACY";
const SOLUTIONS_MODULE = "SOLUTIONS_MODULE";
const STATEMENT_CARDS = "STATEMENT CARDS";
const STICKER_CARDS = "STICKER_CARDS";
const SUMMARY_CARDS = "SUMMARY_CARDS";
const TERMS = "TERMS";

const initialState = {
  billboards: null,
  callToActions: null,
  columnCards: null,
  footer: null,
  gridCards: null,
  headers: null,
  infoCards: null,
  listCards: null,
  listMediaCards: null,
  mapCard: null,
  mediaCards: null,
  preFooter: null,
  privacy: null,
  solutionsModule: null,
  statementCards: null,
  stickerCards: null,
  summaryCards: null,
  terms: null,
};

const StrapiReducer = (state, action) => {
  switch (action.type) {
    case BILLBOARDS:
      return {
        ...state,
        billboards: action.payload,
      };
    case CALL_TO_ACTIONS:
      return {
        ...state,
        callToActions: action.payload,
      };
    case COLUMN_CARDS:
      return {
        ...state,
        columnCards: action.payload,
      };
    case FOOTER:
      return {
        ...state,
        footer: action.payload.footer,
      };
    case GRID_CARDS:
      return {
        ...state,
        gridCards: action.payload,
      };
    case HEADERS:
      return {
        ...state,
        headers: action.payload.headers,
      };
    case INFO_CARDS:
      return {
        ...state,
        infoCards: action.payload,
      };
    case LIST_CARDS:
      return {
        ...state,
        listCards: action.payload,
      };
    case LIST_MEDIA_CARDS:
      return {
        ...state,
        listMediaCards: action.payload,
      };
    case MAP_CARD:
      return {
        ...state,
        mapCard: action.payload,
      };
    case MEDIA_CARDS:
      return {
        ...state,
        mediaCards: action.payload,
      };
    case PREFOOTER:
      return {
        ...state,
        preFooter: action.payload,
      };
    case PRIVACY:
      return {
        ...state,
        privacy: action.payload.privacy,
      };
    case SOLUTIONS_MODULE:
      return {
        ...state,
        solutionsModule: action.payload,
      };
    case STATEMENT_CARDS:
      return {
        ...state,
        statementCards: action.payload,
      };
    case STICKER_CARDS:
      return {
        ...state,
        stickerCards: action.payload,
      };
    case SUMMARY_CARDS:
      return {
        ...state,
        summaryCards: action.payload,
      };
    case TERMS:
      return {
        ...state,
        terms: action.payload,
      };
    default:
      return state;
  }
};

const StrapiContext = createContext(initialState);

function StrapiProvider({ children }) {
  const [state, dispatch] = useReducer(StrapiReducer, initialState);
  // BILLBOARDS
  const fetchingBillboards = useRef(false);
  const fetchBillboards = useCallback(() => {
    fetchingBillboards.current = true;

    axios({
      url: "/api/strapi/billboards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _billboards = [];

          data.forEach((board) => {
            const { id, attributes } = board;
            const { page, index, tag, title, statistics } = attributes;

            _billboards.push({
              id,
              page,
              index,
              tag,
              title,
              statistics,
            });
          });

          dispatch({
            type: BILLBOARDS,
            payload: _billboards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH BILLBOARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingBillboards.current = false;
      });
  }, []);
  // CALL TO ACTIONS
  const fetchingCallToActions = useRef(false);
  const fetchCallToActions = useCallback(() => {
    fetchingCallToActions.current = true;

    axios({
      url: "/api/strapi/call-to-actions",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _callToActions = [];

          data.forEach((callToAction) => {
            const { id, attributes } = callToAction;
            const { page, index, size, title, subTitle, button } = attributes;

            _callToActions.push({
              id,
              page,
              index,
              size,
              title,
              subTitle,
              button,
            });
          });

          dispatch({
            type: CALL_TO_ACTIONS,
            payload: _callToActions,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH CALL TO ACTIONS ERROR: ", err);
      })
      .finally(() => {
        fetchingCallToActions.current = false;
      });
  }, []);
  // COLUMN CARDS
  const fetchingColumnCards = useRef(false);
  const fetchColumnCards = useCallback(() => {
    fetchingColumnCards.current = true;

    axios({
      url: "/api/strapi/column-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _columnCards = [];

          data.forEach((columnCard) => {
            const { id, attributes } = columnCard;
            const { page, index, columns } = attributes;

            _columnCards.push({
              id,
              page,
              index,
              columns,
            });
          });

          dispatch({
            type: COLUMN_CARDS,
            payload: _columnCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH COLUMN CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingColumnCards.current = false;
      });
  }, []);
  // FOOTER
  const fetchingFooter = useRef(false);
  const fetchFooter = useCallback(() => {
    fetchingFooter.current = true;

    axios({
      url: "/api/strapi/footer",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (success) {
          const { attributes } = response.data.data;
          const { navigation } = attributes;

          dispatch({
            type: FOOTER,
            payload: {
              footer: navigation,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH FOOTER ERROR: ", err);
      })
      .finally(() => {
        fetchingFooter.current = false;
      });
  }, []);
  // GRID CARDS
  const fetchingGridCards = useRef(false);
  const fetchGridCards = useCallback(() => {
    fetchingGridCards.current = true;

    axios({
      url: "/api/strapi/grid-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _gridCards = [];

          data.forEach((gridCard) => {
            const { id, attributes } = gridCard;
            const { page, index, title, items } = attributes;

            _gridCards.push({
              id,
              page,
              index,
              title,
              items,
            });
          });

          dispatch({
            type: GRID_CARDS,
            payload: _gridCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH GRID CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingGridCards.current = false;
      });
  }, []);
  // HEADERS
  const fetchingHeaders = useRef(false);
  const fetchHeaders = useCallback(() => {
    fetchingHeaders.current = true;

    axios({
      url: "/api/strapi/headers",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          let _headers = page_headers;
          _(response.data.data)
            .chain()
            .pluck("attributes")
            .each((_fetchedHeader) => {
              if (_fetchedHeader?.page?.name) {
                const {
                  page: { name },
                } = _fetchedHeader;

                _headers[name] = _fetchedHeader;
              }
            })
            .value();

          dispatch({
            type: HEADERS,
            payload: {
              headers: _headers,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH HEADERS ERROR: ", err);
      })
      .finally(() => {
        fetchingHeaders.current = false;
      });
  }, []);
  // INFO CARDS
  const fetchingInfoCards = useRef(false);
  const fetchInfoCards = useCallback(() => {
    fetchingInfoCards.current = true;

    axios({
      url: "/api/strapi/info-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _infoCards = [];

          data.forEach((infoCard) => {
            const { id, attributes } = infoCard;

            _infoCards.push({
              id,
              ...attributes,
            });
          });

          dispatch({
            type: INFO_CARDS,
            payload: _infoCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH INFO CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingInfoCards.current = false;
      });
  }, []);
  // LIST CARDS
  const fetchingListCards = useRef(false);
  const fetchListCards = useCallback(() => {
    fetchingListCards.current = true;

    axios({
      url: "/api/strapi/list-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _listCards = [];

          data.forEach((listCard) => {
            const { id, attributes } = listCard;
            const { page, index, statement, list, image } = attributes;
            let _image = null;
            if ("data" in image && image["data"] != null) {
              _image = image["data"]["attributes"]["url"];
            }

            _listCards.push({
              id,
              page,
              index,
              statement,
              list,
              image: _image,
            });
          });

          dispatch({
            type: LIST_CARDS,
            payload: _listCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH LIST CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingListCards.current = false;
      });
  }, []);
  // LIST MEDIA CARDS
  const fetchingListMediaCards = useRef(false);
  const fetchListMediaCards = useCallback(() => {
    fetchingListMediaCards.current = true;

    axios({
      url: "/api/strapi/list-media-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _listMediaCards = [];

          data.forEach((listMediaCard) => {
            const { id, attributes } = listMediaCard;
            const { page, index, image, mediaPlacement, body, statement } =
              attributes;
            const { url } = image["data"]["attributes"];

            _listMediaCards.push({
              id,
              page,
              index,
              image: url,
              mediaPlacement,
              body,
              statement,
            });
          });

          dispatch({
            type: LIST_MEDIA_CARDS,
            payload: _listMediaCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH LIST MEDIA CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingListMediaCards.current = false;
      });
  }, []);
  // MAP CARD
  const fetchingMapCard = useRef(false);
  const fetchMapCard = useCallback(() => {
    fetchingMapCard.current = true;

    axios({
      url: "/api/strapi/map-card",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          const { id, attributes } = data;
          const { page, index, title, content, legend, map, navigation } =
            attributes;
          const { url } = map.data.attributes;

          dispatch({
            type: MAP_CARD,
            payload: {
              id,
              page,
              index,
              title,
              content,
              legend,
              map: url,
              navigation,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH MAP CARD ERROR: ", err);
      })
      .finally(() => {
        fetchingMapCard.current = false;
      });
  }, []);
  // MEDIA CARDS
  const fetchingMediaCards = useRef(false);
  const fetchMediaCards = useCallback(() => {
    fetchingMediaCards.current = true;

    axios({
      url: "/api/strapi/media-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _mediaCards = [];

          data.forEach((mediaCard) => {
            const { id, attributes } = mediaCard;
            const { page, index, title, content, image } = attributes;
            const { url } = image["data"]["attributes"];
            const hasNoContent = content == null || content["value"] == null;
            const formattedContentValue = !!hasNoContent
              ? ""
              : content["value"].includes("\n")
              ? content["value"].split("\n")
              : content["value"];

            _mediaCards.push({
              id,
              page,
              index,
              title,
              content: {
                ...content,
                value: formattedContentValue,
              },
              image: !!url ? url : null,
            });
          });

          dispatch({
            type: MEDIA_CARDS,
            payload: _mediaCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH MEDIA CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingMediaCards.current = false;
      });
  }, []);
  // PRE FOOTER
  const fetchingPreFooter = useRef(false);
  const fetchPreFooter = useCallback(() => {
    fetchingPreFooter.current = true;

    axios({
      url: "/api/strapi/pre-footer",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { attributes } = response.data.data;
          const { title, content, button } = attributes;

          dispatch({
            type: PREFOOTER,
            payload: {
              title,
              content,
              button,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH PRE-FOOTER ERROR: ", err);
      })
      .finally(() => {
        fetchingPreFooter.current = false;
      });
  }, []);
  // PRIVACY
  const fetchingPrivacy = useRef(false);
  const fetchPrivacy = useCallback(() => {
    fetchingPrivacy.current = true;

    axios({
      url: "/api/strapi/privacy",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { attributes } = response.data.data;
          const { content, title, date } = attributes;
          const paragraphs = content.split("### ");
          let _privacy = {};

          if (!!date) {
            const pDate = new Date(date);
            _privacy["date"] =
              getDateMonthString(pDate) +
              " " +
              pDate.getDate() +
              ", " +
              pDate.getFullYear();
          }

          _privacy["title"] = title;
          _privacy["content"] = paragraphs;

          dispatch({
            type: PRIVACY,
            payload: {
              privacy: _privacy,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH PRIVACY ERROR: ", err);
      })
      .finally(() => {
        fetchingPrivacy.current = false;
      });
  }, []);
  // SOLUTIONS MODULE
  const fetchingSolutionsModule = useRef(false);
  const fetchSolutionsModule = useCallback(() => {
    fetchingSolutionsModule.current = true;

    axios({
      url: "/api/strapi/solutions-module",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { attributes } = response.data.data;
          const { first, second, title } = attributes;
          const leftSide = {
            title: first["title"],
            subTitle: first["subTitle"],
            href: first["href"],
            image: first["image"]["data"]["attributes"]["url"],
          };
          const rightSide = {
            title: second["title"],
            subTitle: second["subTitle"],
            href: second["href"],
            image: second["image"]["data"]["attributes"]["url"],
          };

          dispatch({
            type: SOLUTIONS_MODULE,
            payload: {
              title,
              leftSide,
              rightSide,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH SOLUTIONS MODULE ERROR: ", err);
      })
      .finally(() => {
        fetchingSolutionsModule.current = false;
      });
  }, []);
  // STATEMENT CARDS
  const fetchingStatementCards = useRef(false);
  const fetchStatementCards = useCallback(() => {
    fetchingStatementCards.current = true;

    axios({
      url: "/api/strapi/statement-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _statementCards = [];

          data.forEach((statement_card) => {
            const { id, attributes } = statement_card;
            const { page, index, title, content } = attributes;

            _statementCards.push({
              id,
              page,
              index,
              title,
              content,
            });
          });

          dispatch({
            type: STATEMENT_CARDS,
            payload: _statementCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH STATEMENT CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingStatementCards.current = false;
      });
  }, []);
  // STICKER CARDS
  const fetchingStickerCards = useRef(false);
  const fetchStickerCards = useCallback(() => {
    fetchingStickerCards.current = true;

    axios({
      url: "/api/strapi/sticker-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _stickerCards = [];

          data.forEach((sticker_card) => {
            const { id, attributes } = sticker_card;
            const { page, index, title, stickers } = attributes;

            _stickerCards.push({
              id,
              page,
              index,
              title,
              stickers,
            });
          });

          dispatch({
            type: STICKER_CARDS,
            payload: _stickerCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH STICKER CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingStickerCards.current = false;
      });
  }, []);
  // SUMMARY CARDS
  const fetchingSummaryCards = useRef(false);
  const fetchSummaryCards = useCallback(() => {
    fetchingSummaryCards.current = true;

    axios({
      url: "/api/strapi/summary-cards",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { data } = response.data;
          let _summaryCards = [];

          data.forEach((summary_card) => {
            const { id, attributes } = summary_card;
            const { page, index, title, list, button, image } = attributes;
            let _image = null;
            if ("data" in image && image["data"] != null) {
              _image = image["data"]["attributes"]["url"];
            }
            _summaryCards.push({
              id,
              page,
              index,
              title,
              list,
              button,
              image: _image,
            });
          });

          dispatch({
            type: SUMMARY_CARDS,
            payload: _summaryCards,
          });
        }
      })
      .catch((err) => {
        console.error("FETCH SUMMARY CARDS ERROR: ", err);
      })
      .finally(() => {
        fetchingSummaryCards.current = false;
      });
  }, []);
  // TERMS
  const fetchingTerms = useRef(false);
  const fetchTerms = useCallback(() => {
    fetchingTerms.current = true;

    axios({
      url: "/api/strapi/terms",
      method: "GET",
    })
      .then((response) => {
        const { success } = response.data;

        if (!!success) {
          const { attributes } = response.data.data;
          const { content, title, date } = attributes;
          const paragraphs = content.split("### ");
          const tcDate = new Date(date);
          const formattedDate =
            getDateMonthString(tcDate) +
            " " +
            tcDate.getDate() +
            ", " +
            tcDate.getFullYear();

          dispatch({
            type: TERMS,
            payload: {
              title,
              content: paragraphs,
              date: formattedDate,
            },
          });
        }
      })
      .catch((err) => {
        console.error("FETCH TERMS ERROR: ", err);
      })
      .finally(() => {
        fetchingTerms.current = false;
      });
  }, []);

  return (
    <StrapiContext.Provider
      value={{
        ...state,
        // BILLBOARDS
        fetchingBillboards,
        fetchBillboards,
        // CALL TO ACTIONS
        fetchingCallToActions,
        fetchCallToActions,
        // COLUMN CARDS
        fetchingColumnCards,
        fetchColumnCards,
        // FOOTER
        fetchingFooter,
        fetchFooter,
        // GRID CARDS
        fetchingGridCards,
        fetchGridCards,
        // HEADERS
        fetchingHeaders,
        fetchHeaders,
        // INFO CARDS
        fetchingInfoCards,
        fetchInfoCards,
        // LIST CARDS
        fetchingListCards,
        fetchListCards,
        // LIST MEDIA CARDS
        fetchingListMediaCards,
        fetchListMediaCards,
        // MAP CARD
        fetchingMapCard,
        fetchMapCard,
        // MEDIA CARDS
        fetchingMediaCards,
        fetchMediaCards,
        // PRE FOOTER
        fetchingPreFooter,
        fetchPreFooter,
        // PRIVACY
        fetchingPrivacy,
        fetchPrivacy,
        // SOLUTIONS MODULE
        fetchingSolutionsModule,
        fetchSolutionsModule,
        // STATEMENT CARDS
        fetchingStatementCards,
        fetchStatementCards,
        // STICKER CARDS
        fetchingStickerCards,
        fetchStickerCards,
        // SUMMARY CARDS
        fetchingSummaryCards,
        fetchSummaryCards,
        // TERMS
        fetchingTerms,
        fetchTerms,
      }}
    >
      {children}
    </StrapiContext.Provider>
  );
}

export { StrapiProvider, StrapiContext };
